// see date formats here https://day.js.org/docs/en/display/format
// [h] - escaping characters
import {PLACEHOLDER, ROUND_OPTION, NON_BREAK_SPACE} from './const';

export const LANG_SETTING = {
    ru: {
        DateText: `Самая выгодная цена на ${PLACEHOLDER.DateFormat}`,
        DateFormat: `D${NON_BREAK_SPACE}MMMM${NON_BREAK_SPACE}YYYY`,
        MinLosText: `При бронировании от ${PLACEHOLDER.MinLos}${PLACEHOLDER.MinLosEnd} ${PLACEHOLDER.MinLosDay}`,
        OfferText: `по тарифу «${PLACEHOLDER.Offer}»`,
        UsePriceRound: ROUND_OPTION.No,
        UsePriceSplit: true,
        PriceDelimiter: ',', // by default is dot
    },
    en: {
        DateText: `Best rate available ${PLACEHOLDER.DateFormat}`,
        DateFormat: `MMMM${NON_BREAK_SPACE}D,${NON_BREAK_SPACE}YYYY`,
        MinLosText: `When booking from ${PLACEHOLDER.MinLos} ${PLACEHOLDER.MinLosDay}`,
        OfferText: `on "${PLACEHOLDER.Offer}" special offer`,
        UsePriceRound: ROUND_OPTION.No,
        UsePriceSplit: true,
        PriceDelimiter: ',', // by default is dot
    },
    uz: {
        DateText: `Eng yaxshi narx ${PLACEHOLDER.DateFormat}`,
        DateFormat: 'DD.MM.YYYY',
        MinLosText: `When booking from ${PLACEHOLDER.MinLos} ${PLACEHOLDER.MinLosDay}`,
        OfferText: `on "${PLACEHOLDER.Offer}" special offer`,
        UsePriceRound: ROUND_OPTION.No,
        UsePriceSplit: true,
        PriceDelimiter: ',', // by default is dot
    },
};
